<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.consignment_allot_plans") }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-row :gutter="[16, 16]" type="flex" justify="space-between" align="bottom">
        <a-col flex="1">
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input v-model:value="searchKey"
                       :placeholder="`${$t('warehouse.plan_no')}`"
                       style="width: 250px;"
                       allowClear />
            </a-col>
            <a-col>
              <a-input style="width: 250px;"
                       v-model:value="box"
                       :placeholder="`${$t('warehouse.box_no')}/${$t('common.user_defined_box_no')}`"
                       allowClear />
            </a-col>
            <a-col>
              <SearchProduct ref="refSearchProduct"></SearchProduct>
            </a-col>
            <a-col>
              <a-select v-model:value="selectedConsignment"
                        @change="handleChange"
                        allow-clear
                        optionFilterProp="search-key"
                        show-search
                        :placeholder="$t('warehouse.consignment_code')"
                        style="width: 250px;">
                <a-select-option v-for="item in consignments"
                                 :key="item.value"
                                 :title="`${item.text1}(${item.text})`"
                                 :value="item.value"
                                 :search-key="item.text1 + item.text">
                  {{item.text1}}({{item.text}})
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select v-model:value="selectedStorage"
                        @change="handleChange"
                        allow-clear
                        show-search
                        optionFilterProp="search-key"
                        :placeholder="$t('warehouse.storage_code')"
                        style="width: 250px;">
                <a-select-option v-for="item in storages"
                                 :key="item.value"
                                 :title="`${item.text1}(${item.text})`"
                                 :value="item.value"
                                 :search-key="item.text1 + item.text">
                  {{item.text1}}({{item.text}})
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select v-model:value="selectedPlanStatus"
                        :placeholder="$t('warehouse.status')"
                        allow-clear
                        @change="handleChange"
                        style="width: 250px;">
                <a-select-option v-for="(value,key) in allotPlanStatusEnum"
                                 :key="key"
                                 :title="$t($enumLangkey('allotPlanStatus',value))"
                                 :value="value">
                  {{$t($enumLangkey('allotPlanStatus',value))}}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-button type="primary" @click="handleChange">{{$t('common.query')}}</a-button>
            </a-col>
            <a-col>
              <a-button type="ghost" :loading="exportLoading" @click="handleExport">{{$t('common.export')}}</a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button type="primary"
                    @click="handleCreatePlan"
                    ghost>{{$t('common.create')}}</a-button>
        </a-col>
      </a-row>

      <div class="mt-3">
        <a-table :columns="columns"
                 size="small"
                 :data-source="planList"
                 :scroll="{ x:400,y: wrap.contentHeight - 120 }"
                 :pagination="false"
                 :loading="planListLoading"
                 class="product-table">

          <template #planNo>
            <div>{{$t('warehouse.plan_no')}}</div>
          </template>

          <template #consignmentCode>
            <span>
              {{ $t("warehouse.consignment_code") }}
            </span>
          </template>

          <template #storageCode>
            <span>
              {{ $t("warehouse.storage_code") }}
            </span>
          </template>
          <template #plannedQuantity>
            <span>
              {{ $t("warehouse.planned_quantity") }}
            </span>
          </template>
          <template #plannedBoxQuantity>
            <span>
              {{ $t("warehouse.planned_box_quantity") }}
            </span>
          </template>
          <template #productQuantity>
            <span>
              {{ $t("warehouse.product_quantity") }}
            </span>
          </template>
          <template #inPlanStatus>
            <span>
              {{ $t("warehouse.status") }}
            </span>
          </template>
          <template #storageInPlanNo>
            <span>
              {{ $t("warehouse.storage_in_plan_no") }}
            </span>
          </template>
          <template #creationTime>
            <span>
              {{ $t("warehouse.creation_time") }}
            </span>
          </template>
          <template #updateTime>
            <span>
              {{ $t("warehouse.update_time") }}
            </span>
          </template>
          <template #subsequentStepsTitle>
            <span>
              {{ $t("warehouse.subsequent_steps") }}
            </span>
          </template>
          <template #planNoV="{ record }">
            <CPlanNumberItem :no="record.planNo"></CPlanNumberItem>
          </template>
          <template #planStatusCustom="{ record }">
            {{$t($enumLangkey('allotPlanStatus',record.planStatus))}}
          </template>
          <template #storageInPlanNoCustomer="{ record }">
            {{ record.storageInPlanNo || '-' }}
          </template>

          <template #creationTimeCustom="{ record }">
            {{$filters.utcToCurrentTime(record.creationTime)}}
          </template>
          <template #updateTimeCustom="{ record }">
            {{$filters.utcToCurrentTime(record.updateTime)}}
          </template>

          <template #operate="{ record }">
            <router-link :to="'/consignment/allot/plandetails/' + record.planId">
              <a-button size="small" class="ml-2">
                {{$t('common.details')}}</a-button>
            </router-link>
          </template>

        </a-table>
      </div>

    </template>
    <template #contentFooter>
      <a-row type="flex"
             justify="space-around"
             align="middle">
        <a-col>
          <CPager @handlePage="handlePage"
                  :page-data="pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted, ref, onActivated } from "vue";
import {
  Row, Col,
  Table, Input,
  Select, Button, Form, message,
} from "ant-design-vue";
import Content from "../../components/Content.vue";
import CPager from "../../components/CPager.vue";
import CPlanNumberItem from "../../components/CPlanNumberItem.vue";

import { getAllotPlans, exportTransferToWarehousePlan } from "../../../api/modules/consignment/index";
import { getOptionWarehouses } from "../../../api/modules/common/index";
import { allotPlanStatus as allotPlanStatusEnum } from "../../../enum/enum.json";
import { useRouter } from 'vue-router';
import { useI18n } from "vue-i18n/index";
import { dateString } from '@/utils/general';
import { downloadFile } from '@/utils/downloader.js';
import SearchProduct from "../../components/SearchProduct.vue";

export default defineComponent({
  name: "consignment_allot_plans",
  components: {
    Content, CPager, ARow: Row, ACol: Col, ATable: Table, AInput: Input, ASelect: Select,
    AButton: Button, ASelectOption: Select.Option, AFormItem: Form.Item,
    AForm: Form, CPlanNumberItem,SearchProduct
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();

    const refPage = ref();
    const refSearchProduct = ref();

    const state = reactive({
      allotPlanStatusEnum: allotPlanStatusEnum,
      planListLoading: false,

      selectedPlanStatus: null,
      selectedConsignment: null,
      selectedStorage: null,
      consignments: [],
      storages: [],
      planList: [],
      box:null,
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10
      },
      searchKey: null,
      exportLoading: false,
    });

    const columns = [
      {
        dataIndex: "planNo",
        slots: {
          title: "planNo",
          customRender: "planNoV",
        },
        width: 150,
      },
      {
        dataIndex: "consignmentCode",
        slots: {
          title: "consignmentCode",
        },
        width: 150,
      },
      {
        dataIndex: "storageCode",
        slots: {
          title: "storageCode",
        },
        width: 150,
      },
      {
        dataIndex: "plannedQuantity",
        slots: {
          title: "plannedQuantity",
        },
        width: 150,
      },
      {
        dataIndex: "plannedBoxQuantity",
        slots: {
          title: "plannedBoxQuantity",
        },
        width: 150,
      },
      {
        dataIndex: "productQuantity",
        slots: {
          title: "productQuantity",
        },
        width: 150,
      },

      {
        dataIndex: "inPlanStatus",
        slots: {
          title: "inPlanStatus",
          customRender: "planStatusCustom",
        },
        width: 150,
      },
      {
        slots: {
          title: "storageInPlanNo",
          customRender: "storageInPlanNoCustomer",
        },
        width: 150,
      },
      {
        dataIndex: "creationTime",
        slots: {
          title: "creationTime",
          customRender: "creationTimeCustom",
        },
        width: 150,
      },
      {
        dataIndex: "updateTime",
        slots: {
          title: "updateTime",
          customRender: "updateTimeCustom",
        },
        width: 150,
      },
      {
         title: "",
         dataIndex: "subsequentSteps",
        slots: {
          customRender: "operate",
        },
        fixed: "right",
        width: 100,
      },
    ];

    const handleExport = () => {
      state.exportLoading = true
      let url = exportTransferToWarehousePlan();
      let searchData = {
        searchKey: state.searchKey,
        status: state.selectedPlanStatus,
        delegateWarehouseId: state.selectedConsignment,
        storageWarehouseId: state.selectedStorage,
      }
      downloadFile(url, `allot_plans_${dateString()}.csv`, "POST", searchData).then(() => {
        message.success(vueI18n.t("common.succeed"))
      }).catch(() => { }).finally(() => {
        state.exportLoading = false
      })
    }

    const handlePage = async (pageData) => {
      state.pageData.skipCount = pageData.skipCount;
      state.pageData.maxResultCount = pageData.maxResultCount;
      funcSearchPlans();
    }

    const handleChange = () => {
      state.pageData.skipCount = 0;
      state.pageData.currentIndex = 1;
      funcSearchPlans();
    }

    const funcSearchPlans = async () => {
      let searchData = {
        searchKey: state.searchKey,
        boxNo: state.box,
        productId: refSearchProduct.value.selectedProductId,
        "status": state.selectedPlanStatus,
        "delegateWarehouseId": state.selectedConsignment,
        "storageWarehouseId": state.selectedStorage,
        ...state.pageData
      };
      state.planListLoading = true;
      getAllotPlans(searchData).then((res) => {
        if (res.result) {

          let { items, totalCount } = res.result;
          state.planList = items.map((x, index) => {
            return {
              key: x.id,
              planId: x.id,
              planNo: x.planNo,
              consignmentCode: x.agentWarehouseNo,
              storageCode: x.storeWarehouseNo,
              plannedQuantity: x.planCount,
              plannedBoxQuantity: x.planBoxCount,
              productQuantity: x.productCount,
              planStatus: x.status,
              storageInPlanNo: x.inWarehousePlanNo,
              creationTime: x.creationTime,
              updateTime: x.lastModificationTime,
            }
          })
          state.pageData.totalCount = parseInt(totalCount);
        } else {
          state.pageData.totalCount = 0;
        }
        state.planListLoading = false;
      }).catch(() => {
        state.planListLoading = false;
      });
    }

    const handleCreatePlan = async () => {
      router.push({ path: "/consignment/allot/create" });
    }

    const funcGetConsignments = async () => {
      getOptionWarehouses({ warehouseType: 2 }).then((res) => {
        if (res.result && res.result.length > 0) {
          state.consignments = res.result;
        }
      })
    }

    const funcGetStorages = async () => {
      getOptionWarehouses({ warehouseType: 1 }).then((res) => {
        if (res.result && res.result.length > 0) {
          state.storages = res.result;
        }
      })
    }

    onMounted(async () => {
      funcGetConsignments();
      funcGetStorages();
    })

    onActivated(() => {
      funcSearchPlans();
    })
    return {
      refSearchProduct,
      columns,
      ...toRefs(state),
      refPage,

      handleExport,
      handlePage,
      handleCreatePlan,
      funcSearchPlans,
      handleChange
    };
  }
})
</script>

<style lang="less" scoped>
</style>